import React, { FunctionComponent } from "react";

interface StepsCard {
  data?: any[];
}

const StepsCardComponent: FunctionComponent<StepsCard> = ({ data }) => (
  <div className="my-6">
    {data &&
      data?.map((item: any, idx: number) => (
        <div
          className="flex shadow-2 rounded md:px-6 relative"
          key={`steps-${idx}`}
        >
          <div
            style={{
              borderLeft: data.length !== idx + 1 ? "2px solid #fff" : "",
              minHeight: "70px",
              paddingLeft: "40px",
            }}
            className="text-lightgray-350 text-[16px] md:text-[18px] leading-[24px] flex items-start mt-2 ml-[22px] md:ml-0"
          >
            <p>{item}</p>
          </div>
          <div className="absolute top-0 left-[-6px] min-h-[55px] min-w-[55px] rounded-[20px] bg-[#21d06b] flex justify-center items-center font-bold">
            0{idx + 1}
          </div>
        </div>
      ))}
  </div>
);

export default StepsCardComponent;
