import React from "react";
import basePath from "lib/helpers/basePath";
import StepsCardComponent from "components/common/StepsCard";
import { refinanceSteps } from "DataMapper/Refi/LandingPageConstants";

function RefinanceSteps() {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat min-h-[500px] py-[40px] md:py-[100px] px-[24px] text-[#fff] bg-[#1b1d6a] dots"
      style={{
        backgroundImage: `url(${basePath}/assets/icons/dot-background.png)`,
      }}
    >
      <h2 className="text-[28px] md:text-[35px] leading-[36px] md:leading-[46px] md:text-center font-bold mb-8">
        Refinance your loan in{" "}
        <span className="text-[#21d06b]">5 simple steps</span>
      </h2>
      <div className="lg:grid md:grid grid-cols-2 md:px-[96px]">
        <StepsCardComponent data={refinanceSteps} />
        <div className="my-12 min-h-[204px]">
          <iframe
            className="min-h-[204px] md:h-full md:min-w-[326px] w-full"
            title="How to refinance your education loan"
            src="https://www.youtube.com/embed/IiV0qFBmyv8"
          />
        </div>
      </div>
    </section>
  );
}

export default RefinanceSteps;
